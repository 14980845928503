<template>
    <div class="paramPageClass">
        <el-main style="padding: 0px">
            <el-form :inline="true" :model="paramForm" :rules="formRules" label-position="right" label-width="270px" ref="paramForm" size="small">
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="停止锂电池充电直流母线电压值上限：" prop="stopBatDcBusVMax">
                            <el-input v-model="paramForm.stopBatDcBusVMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">V</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="启动锂电池充电直流母线电压值下限：" prop="startBatDcBusVMin">
                            <el-input v-model="paramForm.startBatDcBusVMin" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">V</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="停止锂电池充电SOC上限：" prop="stopBatSocMax">
                            <el-input v-model="paramForm.stopBatSocMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="启动锂电池充电SOC下限：" prop="startBatSocMin">
                            <el-input v-model="paramForm.startBatSocMin" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="锂电池预热箱加热控制温度上限：" prop="batPLBHCTempMax">
                            <el-input v-model="paramForm.batPLBHCTempMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="锂电池预热箱加热控制温度下限：" prop="batPLBHCTempMix">
                            <el-input v-model="paramForm.batPLBHCTempMix" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="散热轴流风机控制温度上限：" prop="startTempCafMax">
                            <el-input v-model="paramForm.startTempCafMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="散热轴流风机控制温度下限：" prop="stopTempCafMin">
                            <el-input v-model="paramForm.stopTempCafMin" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="设备舱温度上限：" prop="dcTempMax">
                            <el-input v-model="paramForm.dcTempMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="电控舱温度上限：" prop="eccTempMax">
                            <el-input v-model="paramForm.eccTempMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="锂电池充电限流点：" prop="batCle">
                            <el-input v-model="paramForm.batCle" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="本站负载平均电流：" prop="loadAvgI">
                            <el-input v-model="paramForm.loadAvgI" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">A</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="保温策略电控舱温度上限：" prop="batCle">
                            <el-input v-model="paramForm.isEccTempMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="保温策略电控舱温度下限：" prop="loadAvgI">
                            <el-input v-model="paramForm.isEccTempMin" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="保温策略电芯温度上限：" prop="batCle">
                            <el-input v-model="paramForm.isElecTempMax" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="保温策略电芯温度下限：" prop="loadAvgI">
                            <el-input v-model="paramForm.isElecTempMin" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="12" >
                        <el-form-item label="锂电池充满间隔时间：" prop="batCle">
                            <el-input v-model="paramForm.batFillIntervalTime" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" >
                        <el-form-item label="柴发例行启动间隔时间：" prop="loadAvgI">
                            <el-input v-model="paramForm.engRoutineStartTime" placeholder="请输入" autocomplete="off" class="item">
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="24" >
                        <el-form-item label="备注：" prop="remark" :rules="{required: true, message: '请填写指令操作备注', trigger: 'blur'}">
                            <el-input
                                class="remark"
                                v-model="paramForm.remark"
                                maxlength="300"
                                autocomplete="off"
                                placeholder="请填写指令操作备注">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <el-footer style="text-align: center;margin-top: 10px">
            <el-button type="primary" @click="sendParamCmd" :disabled="disableBtn">设置</el-button>
        </el-footer>
    </div>
</template>
<script>
    import websocket from "@/utils/websocket";
    import loading from "@/utils/cmdLoading";
    import message from "@/utils/message";
    import {getToken} from "@/api/auth";
    import {deviceParamConfig, updateDeviceCmdParam} from "../../../../api/station/deviceInfoApi";
    export default {
        name: "device-param-set",
        props: {

            deviceId: {

                type: Number
            },
            deviceNumber: {

                type: String
            }
        },
        data() {

            // 验证为数字
            let validateNum = (rule, value, callback) => {

                let reg = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
                if (value === '') {

                    callback(new Error('不可为空'));
                } else if (!reg.test(value)){

                    callback(new Error('请输入数字'));
                } else {

                    callback();
                }
            };
            return {

                // 实际修改后发送的参数
                paramForm: {

                    stopBatDcBusVMax: '',
                    startBatDcBusVMin: '',
                    stopBatSocMax: '',
                    startBatSocMin: '',
                    batPLBHCTempMax: '',
                    batPLBHCTempMix: '',
                    startTempCafMax: '',
                    stopTempCafMin: '',
                    dcTempMax: '',
                    eccTempMax: '',
                    batCle: '',
                    loadAvgI: '',
                    isEccTempMax:'',
                    isEccTempMin:'',
                    isElecTempMax:'',
                    isElecTempMin:'',
                    batFillIntervalTime:'',
                    engRoutineStartTime:'',
                    remark: ''
                },
                // 读取到的参数
                compareParamForm:{

                    stopBatDcBusVMax: '',
                    startBatDcBusVMin: '',
                    stopBatSocMax: '',
                    startBatSocMin: '',
                    batPLBHCTempMax: '',
                    batPLBHCTempMix: '',
                    startTempCafMax: '',
                    stopTempCafMin: '',
                    dcTempMax: '',
                    eccTempMax: '',
                    batCle: '',
                    loadAvgI: '',
                    isEccTempMax:'',
                    isEccTempMin:'',
                    isElecTempMax:'',
                    isElecTempMin:'',
                    batFillIntervalTime:'',
                    engRoutineStartTime:''
                },
                disableBtn: false,
                formRules: {
                    stopBatDcBusVMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    startBatDcBusVMin: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    stopBatSocMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    startBatSocMin: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    batPLBHCTempMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    batPLBHCTempMix: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    startTempCafMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    stopTempCafMin: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    dcTempMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    eccTempMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    batCle: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    loadAvgI: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    isEccTempMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    isEccTempMin: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    isElecTempMax: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    isElecTempMin: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    batFillIntervalTime: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                    engRoutineStartTime: [
                        {required: true, type: 'number', validator: validateNum, trigger: ['blur']}
                    ],
                },
                topic: "/user/" + getToken() + "/deviceParamSet",
                cmdLoading: null
            }
        },
        methods: {

            // 初始化页面查询数据
            initPage() {

                this.unSub();
                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                // 清空校验
                this.$nextTick(()=>{

                    this.$refs['paramForm'].clearValidate();
                });
                // 参数未回来之前禁用
                this.disableBtn = true;
                websocket.initWebSocket(this.topic, this.wsCallback);
                let param = {

                    deviceNum: this.deviceNumber,
                    cmdCode: '0831',
                    deviceWholeId: this.deviceId
                };
                deviceParamConfig({message: JSON.stringify(param)}).then((res) => {

                    if (res.code === '100') {

                        this.cmdLoading = loading.loading("参数读取中...", 60);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            },
            // 发送设置参数指令
            sendParamCmd() {

                this.$refs["paramForm"].validate((valid) => {

                    if (valid) {

                        this.confirmSendParamCmd();
                    }
                });
            },
            // 提示是否发送指令，且参数修改的地方
            confirmSendParamCmd() {

                // 取出修改的值放入该数组
                let messageArr = [];
                const h = this.$createElement;
                // 修改后的值
                let after = this.paramForm;
                // 修改前的值
                let before = this.compareParamForm;
                if (before.stopBatDcBusVMax !== after.stopBatDcBusVMax) {

                    messageArr.push(h('p', null, "停止锂电池充电直流母线电压值（上限）由【" + before.stopBatDcBusVMax + "】修改为【" + after.stopBatDcBusVMax + "】"));
                }
                if (before.startBatDcBusVMin !== after.startBatDcBusVMin) {

                    messageArr.push(h('p', null, "启动锂电池充电直流母线电压值（下限）由【" + before.startBatDcBusVMin + "】修改为【" + after.startBatDcBusVMin + "】"));
                }
                if (before.stopBatSocMax !== after.stopBatSocMax) {

                    messageArr.push(h('p', null, "停止锂电池充电SOC值（上限）由【" + before.stopBatSocMax + "】修改为【" + after.stopBatSocMax + "】"));
                }
                if (before.startBatSocMin !== after.startBatSocMin) {

                    messageArr.push(h('p', null, "启动锂电池充电SOC值（下限）由【" + before.startBatSocMin + "】修改为【" + after.startBatSocMin + "】"));
                }
                if (before.batPLBHCTempMax !== after.batPLBHCTempMax) {

                    messageArr.push(h('p', null, "锂电池预加箱加热控制温度上限由【" + before.batPLBHCTempMax + "】修改为【" + after.batPLBHCTempMax + "】"));
                }
                if (before.batPLBHCTempMix !== after.batPLBHCTempMix) {

                    messageArr.push(h('p', null, "锂电池预加箱加热控制温度下限由【" + before.batPLBHCTempMix + "】修改为【" + after.batPLBHCTempMix + "】"));
                }
                if (before.startTempCafMax !== after.startTempCafMax) {

                    messageArr.push(h('p', null, "散热轴流风机启动温度（上限）由【" + before.startTempCafMax + "】修改为【" + after.startTempCafMax + "】"));
                }
                if (before.stopTempCafMin !== after.stopTempCafMin) {

                    messageArr.push(h('p', null, "散热轴流风机停止温度（下限）由【" + before.stopTempCafMin + "】修改为【" + after.stopTempCafMin + "】"));
                }
                if (before.dcTempMax !== after.dcTempMax) {

                    messageArr.push(h('p', null, "设备舱温度上限由【" + before.dcTempMax + "】修改为【" + after.dcTempMax + "】"));
                }
                if (before.eccTempMax !== after.eccTempMax) {

                    messageArr.push(h('p', null, "电控舱温度上限由【" + before.eccTempMax + "】修改为【" + after.eccTempMax + "】"));
                }
                if (before.batCle !== after.batCle) {

                    messageArr.push(h('p', null, "锂电池充电限流点由【" + before.batCle + "】修改为【" + after.batCle + "】"));
                }
                if (before.loadAvgI !== after.loadAvgI) {

                    messageArr.push(h('p', null, "本站负载平均电流由【" + before.loadAvgI + "】修改为【" + after.loadAvgI + "】"));
                }
                if (before.isEccTempMax !== after.isEccTempMax) {

                    messageArr.push(h('p', null, "保温策略电控舱温度上限【" + before.isEccTempMax + "】修改为【" + after.isEccTempMax + "】"));
                }
                if (before.isEccTempMin !== after.isEccTempMin) {

                    messageArr.push(h('p', null, "保温策略电控舱温度下限【" + before.isEccTempMin + "】修改为【" + after.isEccTempMin + "】"));
                }
                if (before.isElecTempMax !== after.isElecTempMax) {

                    messageArr.push(h('p', null, "保温策略电芯温度上限【" + before.isElecTempMax + "】修改为【" + after.isElecTempMax + "】"));
                }
                if (before.isElecTempMin !== after.isElecTempMin) {

                    messageArr.push(h('p', null, "保温策略电芯温度下限【" + before.isElecTempMin + "】修改为【" + after.isElecTempMin + "】"));
                }
                if (before.batFillIntervalTime !== after.batFillIntervalTime) {

                    messageArr.push(h('p', null, "锂电池充满间隔时间【" + before.batFillIntervalTime + "】修改为【" + after.batFillIntervalTime + "】"));
                }
                if (before.engRoutineStartTime !== after.engRoutineStartTime) {

                    messageArr.push(h('p', null, "柴发例行启动间隔时间【" + before.engRoutineStartTime + "】修改为【" + after.engRoutineStartTime + "】"));
                }
                if (messageArr.length == 0) {

                    messageArr.push(h('p', null, "此次指令操作未进任何参数的修改，确认发送指令吗？"));
                } else {

                    messageArr.push(h('p', null, "确认要进行如上参数的修改吗？"));
                }
                this.$confirm("", {
                        title:'提示',
                        message: h('ul', null, messageArr),
                        confirmButtonText: "确定",
                        cancelButtonText:"取消",
                        type:"info",
                        customClass:"param-custom-confirm"
                }).then(() => {

                    console.log("确认完成发送指令");
                    this.reconfirmSendParamCmd();
                }).catch(() => {

                    console.log("取消");
                });
            },
            // 再次确认完成发送指令
            reconfirmSendParamCmd() {

                let params = {

                    esCMDInfo: JSON.parse(JSON.stringify(this.paramForm)),
                    deviceNum : this.deviceNumber,
                    cmdCode: '0832',
                    deviceWholeId: this.deviceId,
                    remark: this.paramForm.remark
                };
                // 启动锂电池充电直流母线电压值（下限） 需要乘10
                params.esCMDInfo.startBatDcBusVMin = params.esCMDInfo.startBatDcBusVMin * 10;
                // 停止锂电池充电直流母线电压值（上限） 需要乘10
                params.esCMDInfo.stopBatDcBusVMax = params.esCMDInfo.stopBatDcBusVMax * 10;
                // 锂电池充电限流点 需要乘100
                params.esCMDInfo.batCle = params.esCMDInfo.batCle * 100;
                deviceParamConfig({message: JSON.stringify(params)}).then((res) => {

                    if (res.code === '100') {

                        this.cmdLoading = loading.loading("参数设置中...", 60);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            },
            wsCallback(msg) {

                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                let data = JSON.parse(msg.body);
                let code = data.instructionCode;
                // 指令码为0x0931 = 2353 读取参数响应
                if (code == "2353") {

                    // 渲染页面数据
                    message.success("参数读取成功")
                    this.paramForm = data.messageBody.esCMDInfo;
                    // 启动锂电池充电直流母线电压值（下限） 需要除10
                    this.paramForm.startBatDcBusVMin = this.paramForm.startBatDcBusVMin / 10;
                    // 停止锂电池充电直流母线电压值（上限） 需要除10
                    this.paramForm.stopBatDcBusVMax = this.paramForm.stopBatDcBusVMax / 10;
                    // 锂电池充电限流点 需要除100
                    this.paramForm.batCle = this.paramForm.batCle / 100;
                    this.disableBtn = false;
                    // 留存一份用于修改后比对
                    this.compareParamForm = JSON.parse(JSON.stringify(this.paramForm));
                }
                // 指令码为0x0932 = 2354 设置参数响应成功则需要将当前设置成功的值放入compareParam中
                // 防止在页面上进行连续修改时,提示不正确的问题
                if (code == "2354") {

                    message.success("参数设置成功")
                    let updateParam = this.paramForm;
                    updateParam.deviceWholeId = this.deviceId;
                    updateParam.startBatDcBusVMin = updateParam.startBatDcBusVMin;
                    updateParam.stopBatDcBusVMax = updateParam.stopBatDcBusVMax;
                    updateParam.batCle = updateParam.batCle;
                    updateDeviceCmdParam(updateParam).then((res) => {

                        if(res.code != "100") {

                            message.error('指令执行成功后更新数据库失败，请重试...')
                        }
                    });
                    this.compareParamForm = JSON.parse(JSON.stringify(this.paramForm));
                }
            },
            unSub() {

                websocket.unSubTopic(this.topic);
            }
        }
    }
</script>
<style scoped="scoped">
    .paramPageClass {

        width: 1150px;
    }
    .item {

        width: 250px;
    }
    .remark {

        width: 825px;
    }
    /deep/ .el-input-group__append {

        width: 15px;
    }
</style>
<style>
    .param-custom-confirm {

        width: 560px;
    }

</style>